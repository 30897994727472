/* eslint-disable no-useless-computed-key */
import {
  Box,
  Center,
  Heading,
  Spinner,
  Flex,
  Button,
  useDisclosure,
  Icon,
} from "@chakra-ui/react";
import { TbWorldWww } from "react-icons/tb";
import { FaFileImage } from "react-icons/fa";

import { FaFilePdf } from "react-icons/fa6";
import React, { useContext, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import Layout from "../Layout/Layout";
import Table from "../Tables/Table";
import AddDataSources from "../Modals/AddDataSource";
import Loader from "../../utils/Loader/Loader";
import { WrappedModal } from "../WithInstructionModal/WrappedModal";
import DataSourceStatusIcon from "./DataSourceStateIcon";
import DatasourcesTour from "./DatasourcesTour";
import { FaImage } from "react-icons/fa";
import { MdOutlineTextSnippet } from "react-icons/md";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
import Pagination from "../../utils/Loader/Pagination";
const DataSources = ({ tab }) => {
  const baseUrl = getHostDomain();
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const [status, setStatus] = useState("idle");
  const [isInitialLoad, setIsInitialLoad] = useState(true); // New state variable
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const endpoint = "/api/v1/datasource/get_my_details";
  const urlHit = baseUrl + endpoint;

  const IconComponent = ({ dsType }) => {
    if (dsType === "pdf") {
      return (
        <div style={{ marginLeft: "8px" }}>
          <Icon as={FaFilePdf} color="red" />
        </div>
      );
    } else if (dsType === "http") {
      return (
        <div style={{ marginLeft: "8px" }}>
          <Icon as={TbWorldWww} color="blue" />
        </div>
      );
    } else if (dsType === "image") {
      return (
        <div style={{ marginLeft: "8px" }}>
          <Icon as={FaImage} color="blue" />
        </div>
      );
    } else if (dsType === "text_blob") {
      return (
        <Box marginLeft={2}>
          <Icon as={MdOutlineTextSnippet} color={"blue"} />
        </Box>
      );
    }
    else if (dsType === "text_image") {
      return (
        <Box marginLeft={2}>
          <Icon as={FaFileImage} color={"blue"} />
        </Box>
      );
    }
    return null;
  };
  const hitFetch = async () => {
    try {
      setStatus("loading");
      const getData = await fetchController(urlHit, "GET");
      if (getData?.data) {
        const updatedData = getData.data.items.map((item) => {
          return {
            Status: <DataSourceStatusIcon ds_status={item.ds_status} />,
            ...item,
            Type: <IconComponent dsType={item.ds_type} />,
            ["assistants"]: (
              <div style={{ marginLeft: "35px" }}>
                {item.assistants_count.toLocaleString()}
              </div>
            ),
            ["data_source_files"]: (
              <div style={{ marginLeft: "35px" }}>
                {item.data_source_files_count.toLocaleString()}
              </div>
            ),

            ["word_count"]: (
              <div style={{ marginLeft: "35px" }}>
                {item.total_word_count.toLocaleString()}
              </div>
            ),
          };
        });
        const heads = [
          "Status",
          "Type",
          "name",
          "description",
          "assistants",
          "data_source_files",
          "word_count",
        ];
        const columns = heads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
          Cell: ({ value }) => {
            // Custom rendering for the "sourceFiles" column to display the count
            if (Array.isArray(value)) {
              return <div style={{ marginLeft: "20px" }}>{value.length}</div>;
            }
            return value;
          },
        }));
        setTableData(updatedData);
        setHeads(columns);
        setStatus("success");
        setIsInitialLoad(false); // Set isInitialLoad to false after the initial load
      }
    } catch (error) {
      setStatus("failure");
    }
  };
  useEffect(() => {
    if (tab === "Data Sources") {
      hitFetch();
    }
  }, [tab]);

  const loading = status === "loading" && isInitialLoad;
  const [datasourcesTourVisibility, setDatasourcesTourVisibility] = useState(
    localStorage.getItem("datasourcesTourVisibility") ? false : true
  );
  const onCloseDatasourcesTour = () => {
    localStorage.setItem("datasourcesTourVisibility", false);
    setDatasourcesTourVisibility(false);
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;

  const totalPages = Math.ceil(tableData?.length / itemsPerPage);

  const currentItems = tableData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Box
        border="1px"
        h="81vh"
        // scrollBehavior={"smooth"}
        overflowY="scroll"
        p="5"
        borderRadius="md"
      >
        <Heading color={textColor} fontSize="xl">
          Data Sources
        </Heading>
        {datasourcesTourVisibility ? (
          <DatasourcesTour onCloseDatasourcesTour={onCloseDatasourcesTour} />
        ) : null}
        <Flex justifyContent="flex-end" alignItems="center" mb="4">
          <Button
            colorScheme={buttonColorScheme}
            onClick={() => {
              onOpen();
            }}
          >
            Add Data Source
          </Button>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              columns={heads}
              data={currentItems}
              table="data-sources"
              fetch={hitFetch}
            />

            {currentItems?.length && (
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  colorScheme={buttonColorScheme}
                />
              </Flex>
            )}
          </Box>
        )}
      </Box>
      <AddDataSources
        fetchSources={hitFetch}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
      />
    </>
  );
};

export default DataSources;
