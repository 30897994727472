/* eslint-disable no-useless-computed-key */
import { Box, Button, Flex, Heading } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import Table from "../Tables/Table";
import AssistantTour from "./AssistantsTour";
import { TbMessageChatbot } from "react-icons/tb";
import { FaPhone } from "react-icons/fa6";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { getHostDomain } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import Pagination from "../../utils/Loader/Pagination";

const Assistants = ({ tab }) => {
  const baseUrl = getHostDomain();
  const navigate = useNavigate();

  const [status, setStatus] = useState("idle");
  const [isInitialLoad, setIsInitialLoad] = useState(true); // New state variable
  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const endpoint = "/api/v1/assistant/get_my_details";
  const urlHit = baseUrl + endpoint;

  const hitFetch = async () => {
    try {
      setStatus("loading");
      const getData = await fetchController(urlHit, "GET");
      if (getData?.data) {
        const updatedData = getData.data.items.map((item) => {
          return {
            assistant_type: item.assistant_type,
            type:
              item.assistant_type === "phone" ? (
                <FaPhone />
              ) : (
                <TbMessageChatbot />
              ),
            name: item.name,
            description: item.description,
            ["data_Sources"]: (
              <Box ml={12}>
                {item.assistant_type === "phone"
                  ? "-"
                  : item.data_sources_count.toLocaleString()}
              </Box>
            ),
            ["total_word_count"]: (
              <Box ml={12}>
                {item.assistant_type === "phone"
                  ? "-"
                  : item.total_word_count.toLocaleString()}
              </Box>
            ),
            ["intents_count"]: (
              <Box ml={12}>
                {item.assistant_type === "phone" ? "-" : item.intents_count}
              </Box>
            ),
            id: item.id,
            webhook_id: item.webhook_id,
          };
        });
        const headsData = [
          "type",
          "name",
          "description",
          "data_Sources",
          "total_word_count",
          "intents_count",
        ];
        const columns = headsData.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));

        setTableData(updatedData);
        setHeads(columns);
      }
      setStatus("success");
      setIsInitialLoad(false);
    } catch (error) {
      setStatus("failure");
    }
  };

  useEffect(() => {
    if (tab === "Assistants") {
      hitFetch();
    }
  }, [tab]);

  const loading = status === "loading" && isInitialLoad;
  const [assistantTourVisibility, setAssistantTourVisibility] = useState(
    localStorage.getItem("assistantTourVisibility") ? false : true
  );
  const onCloseAssistantTour = () => {
    localStorage.setItem("assistantTourVisibility", false);
    setAssistantTourVisibility(false);
  };
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme, colorPreset } = agency;

  const totalPages = Math.ceil(tableData?.length / itemsPerPage);

  const currentItems = tableData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      <Box border="1px" h="81vh" overflowY="scroll" p="5" borderRadius="md">
        <Heading color={textColor} fontSize="xl">
          Assistants
        </Heading>
        {assistantTourVisibility ? (
          <AssistantTour onCloseAssistantTour={onCloseAssistantTour} />
        ) : null}
        <Flex justifyContent="flex-end" alignItems="center" mb="4">
          <Button
            colorScheme={buttonColorScheme}
            onClick={() => navigate("/ai-agents/assistants/new")}
          >
            Add an Assistant
          </Button>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={currentItems}
              table="assistant"
            />

            {currentItems?.length && (
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                marginBlock={2}
              >
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  setCurrentPage={setCurrentPage}
                  colorScheme={buttonColorScheme}
                />
              </Flex>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default Assistants;
