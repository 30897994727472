import { Button, ButtonGroup } from "@chakra-ui/react";

export default function Pagination({
  currentPage,
  totalPages,
  setCurrentPage,
  colorScheme,
}) {
  const handlePageChange = (newPage) => {
    if (newPage > 0 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };
  return (
    <ButtonGroup justifyContent={"center"} alignItems={"center"} gap={3}>
      <Button
        colorScheme={colorScheme}
        size={"sm"}
        isDisabled={currentPage === 1}
        onClick={() => handlePageChange(currentPage - 1)}
      >
        Previous
      </Button>
      <code>
        {currentPage} / {totalPages}
      </code>
      <Button
        colorScheme={colorScheme}
        size={"sm"}
        isDisabled={currentPage === totalPages}
        onClick={() => handlePageChange(currentPage + 1)}
      >
        Next
      </Button>
    </ButtonGroup>
  );
}
